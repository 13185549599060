<template>
<AuthLayout>
<template #content>
  
  <div class="NotFoundPageCms">
    
    <div class="NotFoundPageCms__body">
      <div class="NotFoundPageCms__left">
        <img
          class="NotFoundPageCms__img"
          src="@/assets/img/404.svg"
        >
      </div>
      <div class="NotFoundPageCms__right">
        <div class="NotFoundPageCms__title">
          Not found
        </div>
        <div class="NotFoundPageCms__subtitle">
          The requested page was not found on this server
        </div>
    
        <a-button
          class="ant-btn-huge NotFoundPageCms__home"
          type="green"
          size="large"
          @click="$router.push({ path: '/cms' })"
        >
          Go to home
        </a-button>
      </div>
    </div>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/cms/auth.layout/AuthLayout"

export default {
  name: "NotFoundPageCms",
  components: {
    AuthLayout,
  },
}
</script>

<style lang="scss" scoped>
.NotFoundPageCms {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  &__right {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  &__title {
    color: $primary10;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
  }
  
  &__subtitle {
    color: $primary10;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  
  &__home {
    width: 150px;
    line-height: 0;
    height: 32px;
    margin-top: 30px;
  }
}
</style>